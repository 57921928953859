var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2"},[_vm._v(" Delete "+_vm._s(_vm.player.name)+"? ")]),_c('v-card-text',[_vm._v(" Are yo usure you want to delete this player? ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" CANCEL ")]),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.doDeletePlayer}},[_vm._v(" DELETE ")])],1)],1)],1),_c('v-card',{staticClass:"player-card"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-toolbar',{staticClass:"player-toolbar",attrs:{"color":"indigo","dark":""}},[_c('v-tooltip',{attrs:{"bottom":"","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[(!_vm.editingPlayerName)?_c('v-toolbar-title',_vm._g(_vm._b({on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.doEditPlayerName($event)}}},'v-toolbar-title',attrs,false),on),[_vm._v(" "+_vm._s(_vm.player.name)+" ")]):_vm._e(),(_vm.editingPlayerName)?_c('v-toolbar-title',{staticClass:"toolbar-title-edit"},[_c('v-text-field',{ref:"playerNameEditField",staticClass:"edit-field",attrs:{"label":"Press enter to save","placeholder":"Player Name","outlined":"","dense":"","hide-details":"true"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.endEditPlayerName($event)},"blur":_vm.endEditPlayerName},model:{value:(_vm.player.name),callback:function ($$v) {_vm.$set(_vm.player, "name", $$v)},expression:"player.name"}})],1):_vm._e()],1)]}}],null,true)},[_vm._v(" Click to edit player name ")]),_c('Stepper',{attrs:{"count":_vm.player.health,"icon":"mdi-heart","iconoffset":"2"}}),_c('v-tooltip',{directives:[{name:"show",rawName:"v-show",value:(hover || _vm.player.is_active_player || _vm.$vuetify.breakpoint.mobile),expression:"hover || player.is_active_player || $vuetify.breakpoint.mobile"}],attrs:{"bottom":"","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(hover || _vm.player.is_active_player || _vm.$vuetify.breakpoint.mobile),expression:"hover || player.is_active_player || $vuetify.breakpoint.mobile"}],attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.setactiveplayerfn(_vm.player)}}},'v-btn',attrs,false),on),[(_vm.player.is_active_player)?_c('v-icon',[_vm._v("mdi-account-check")]):_vm._e(),(!_vm.player.is_active_player)?_c('v-icon',[_vm._v("mdi-account-outline")]):_vm._e()],1)]}}],null,true)},[(_vm.player.is_active_player)?_c('span',[_vm._v("It's this person's turn")]):_vm._e(),(!_vm.player.is_active_player)?_c('span',[_vm._v("Set as this player's turn")]):_vm._e()]),_c('v-tooltip',{directives:[{name:"show",rawName:"v-show",value:(hover || _vm.player.is_monarch || _vm.$vuetify.breakpoint.mobile),expression:"hover || player.is_monarch || $vuetify.breakpoint.mobile"}],attrs:{"bottom":"","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(hover || _vm.player.is_monarch || _vm.$vuetify.breakpoint.mobile),expression:"hover || player.is_monarch || $vuetify.breakpoint.mobile"}],attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.setmonarchfn(_vm.player)}}},'v-btn',attrs,false),on),[(_vm.player.is_monarch)?_c('v-icon',[_vm._v("mdi-crown")]):_vm._e(),(!_vm.player.is_monarch)?_c('v-icon',[_vm._v("mdi-crown-outline")]):_vm._e()],1)]}}],null,true)},[(_vm.player.is_monarch)?_c('span',[_vm._v("This player is the Monarch")]):_vm._e(),(!_vm.player.is_monarch)?_c('span',[_vm._v("Claim the Monarchy")]):_vm._e()]),_c('v-spacer'),_c('v-tooltip',{directives:[{name:"show",rawName:"v-show",value:(hover || _vm.$vuetify.breakpoint.mobile),expression:"hover || $vuetify.breakpoint.mobile"}],attrs:{"bottom":"","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(hover || _vm.$vuetify.breakpoint.mobile),expression:"hover || $vuetify.breakpoint.mobile"}],attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.doUntapAll($event)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-undo-variant")])],1)]}}],null,true)},[_vm._v(" Untap All ")]),_c('v-tooltip',{directives:[{name:"show",rawName:"v-show",value:(hover || _vm.$vuetify.breakpoint.mobile),expression:"hover || $vuetify.breakpoint.mobile"}],attrs:{"bottom":"","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(hover || _vm.$vuetify.breakpoint.mobile),expression:"hover || $vuetify.breakpoint.mobile"}],attrs:{"disabled":_vm.player.graveyard.length == 0,"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();_vm.showGraveyard = !_vm.showGraveyard}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-grave-stone")])],1)]}}],null,true)},[_vm._v(" Show Graveyard / Exiled ")]),_c('v-tooltip',{directives:[{name:"show",rawName:"v-show",value:(hover || _vm.$vuetify.breakpoint.mobile),expression:"hover || $vuetify.breakpoint.mobile"}],attrs:{"bottom":"","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(hover || _vm.$vuetify.breakpoint.mobile),expression:"hover || $vuetify.breakpoint.mobile"}],attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.doSortCards('name')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-sort-alphabetical-descending-variant")])],1)]}}],null,true)},[_vm._v(" Sort Cards Alphabetically ")]),_c('v-tooltip',{directives:[{name:"show",rawName:"v-show",value:(hover || _vm.$vuetify.breakpoint.mobile),expression:"hover || $vuetify.breakpoint.mobile"}],attrs:{"bottom":"","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(hover || _vm.$vuetify.breakpoint.mobile),expression:"hover || $vuetify.breakpoint.mobile"}],attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.doSortCards('type')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-sort-bool-ascending")])],1)]}}],null,true)},[_vm._v(" Sort Cards By Type ")]),_c('v-tooltip',{directives:[{name:"show",rawName:"v-show",value:(hover || _vm.$vuetify.breakpoint.mobile),expression:"hover || $vuetify.breakpoint.mobile"}],attrs:{"bottom":"","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(hover || _vm.$vuetify.breakpoint.mobile),expression:"hover || $vuetify.breakpoint.mobile"}],attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();_vm.dialog = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_vm._v(" Delete Player ")])],1)]}}])}),_c('drop-list',{staticClass:"cards",attrs:{"items":_vm.player.cards,"column":true,"mode":"cut"},on:{"insert":_vm.onInsert,"reorder":_vm.onReorder},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('drag',{key:item.table_card_id,staticClass:"item",attrs:{"data":item,"disabled":_vm.$vuetify.breakpoint.mobile},on:{"cut":function($event){return _vm.remove(item)}}},[(item.drag_type=='card')?_c('Card',{attrs:{"carddata":item,"closefn":_vm.remove,"duplicatefn":_vm.onDuplicate,"deletefn":_vm.doDeleteFromPlayer}}):_vm._e(),(item.drag_type=='counter')?_c('Counter',{attrs:{"counterdata":item,"closefn":_vm.remove}}):_vm._e()],1)]}},{key:"feedback",fn:function(ref){
var data = ref.data;
return [_c('div',{key:data.table_card_id,staticClass:"item feedback"},[(data.drag_type=='card')?_c('Card',{attrs:{"carddata":data}}):_vm._e(),(data.drag_type=='counter')?_c('Counter',{attrs:{"counterdata":data}}):_vm._e()],1)]}},{key:"reordering-feedback",fn:function(ref){return [_c('div',{key:"feedback",staticClass:"reordering-feedback"})]}}])}),_c('v-overlay',{staticClass:"player-graveyard-container",attrs:{"absolute":false,"opacity":0.8,"value":_vm.showGraveyard}},[_c('v-card',{staticClass:"player-graveyard"},[_c('v-toolbar',{staticClass:"player-toolbar",attrs:{"color":"indigo","dark":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.player.name)+"'s Graveyard ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();_vm.showGraveyard = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('div',{staticClass:"graveyard_card_list"},_vm._l((_vm.player.graveyard),function(card){return _c('Card',{key:card.table_card_id,attrs:{"carddata":card,"closefn":_vm.remove,"duplicatefn":_vm.onDuplicate,"returntoplayfn":_vm.onReturnToPlay,"deletefn":_vm.doDeleteFromGraveyard}})}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }